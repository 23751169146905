.edContainer {
  margin-top: 20px;
  padding-bottom: 20px;
}
.schoolNameDiv {
  margin-top: 0px;
}
.school {
  margin-bottom: -20px;
}
.edDegreeYearDiv {
  display: flex;
  justify-content: space-between;
  margin-top: -34px;
}
.schoolNameDiv h5 {
  margin-top: -16px;
}
.school H3 {
  font-size: 1rem;
}
.school h4 {
  font-size: .83rem;
}
.schoolCity {
  margin-top: -10px;
  margin-bottom: 25px;
}