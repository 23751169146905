.liMisc {
  list-style: none;
  margin-top: -20px;
  display: list-item;
  text-align: none;
}
.ulMisc {
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.miscP {
  display: inline-block;
  margin-block-start: none;
  margin-block-end: none;
  margin-inline-start: none;
  margin-inline-end: none;
}
