.bubblesContainer {
  position: fixed;
  bottom: 0px;
  z-index: -1;
}
.resumePageContainer {
  margin-top: -26px;
}
.App {
  overflow: hidden;
}
main {
  margin-top: 60px;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}