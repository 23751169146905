.mainPageHeader {
  display: none;
}
.containerMain {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: calc(100vh - 60px);
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  /* background-color: black;
  z-index: -3; */
}
.mainImg {
  position: relative;
  height: 400px;
  width: auto;
  top: 50px;
  left: -200px;
  right: auto;
  display: block;
}
.imgContainer {
  width: 44%;
  margin-left: auto;
  margin-right: auto;
}
.introDiv {
  width: 56%;
  height: 50vh;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 6px;
}

@media screen and (min-width: 700px) {
  .mainImg {
    left: 0px;
  }
  .introDiv {
    width: 50%;
  }
  .imgContainer {
    width: 50%;
  }
}

@media screen and (orientation: landscape) and (max-width: 736px) and (max-height: 414px) {
  .containerMain {
    margin-top: -140px;
    height: 160vh;
  }
  .imgContainer {
    margin-top: 60px;
  }
}
@media screen and (orientation: landscape) and (min-width: 812px) and (max-height: 375px) {
  .containerMain {
    margin-top: -140px;
    height: 160vh;
  }
  .introDiv {
    margin-top: 120px;
  }
  .imgContainer {
    margin-top: 60px;
  }
}
