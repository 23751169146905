@import url('https://fonts.googleapis.com/css2?family=Fascinate+Inline&family=Faster+One&family=Frijole&family=Righteous&family=Ruslan+Display&display=swap');

.h1AboutMe {
  color: #232763;
  font-size: 2em;
  font-family: "Fascinate Inline";
  text-align: center;
}
.aboutMeContainer {
  width:90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}
.aboutMePContainer {
  background: #ffffff;
  border-radius: 10px;
  padding: 4px;
  font-size: 1em;
  border: 2px solid #232763;
  border-radius: 10px;
}
.aboutMeP {
  padding: 2px;
  font-family: monospace;
}