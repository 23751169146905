.projectsH1 {
  color: #232763;
  font-size: 2em;
  font-family: "Fascinate Inline";
  text-align: center;
}
.projectsIntro {
  background: rgba(225, 227, 234, 1);
  border-radius: 10px;
  padding: 18px;
  font-size: 1.1em;
  border: 2px solid #232763;
  border-radius: 10px;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  text-align: left;
  font-family: monospace;
  font-weight: 700;
}