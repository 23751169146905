
@import url('https://fonts.googleapis.com/css2?family=Fascinate+Inline&family=Faster+One&family=Frijole&family=Righteous&family=Ruslan+Display&display=swap');

.intro {
  color: #232763;
  font-family: "Fascinate Inline";
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 0px 20px 20px rgba(225, 227, 234, 1);
  background-color: rgba(225, 227, 234, 1);
}
.h1Container {
  background:linear-gradient(rgba(255,255,255,.1), rgba(255,255,255,.8), rgba(255,255,255,.1));
  width: fit-content;
  font-size: 1.9rem;
}

/* @media screen and (min-width: 700px) {
  .intro {
    font-size: 2.5rem;
  }
} */
