@import url(/path/to/reset-css/reset.css);
@import url(https://fonts.googleapis.com/css2?family=Fascinate+Inline&family=Faster+One&family=Frijole&family=Righteous&family=Ruslan+Display&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fascinate+Inline&family=Faster+One&family=Frijole&family=Righteous&family=Ruslan+Display&display=swap);
body{
   padding:0 !important;
   margin:0 !important;
   box-sizing: border-box !important;
   background-color: rgba(225, 227, 234, 1);
 }


.header {
  font-family: 'Righteous', cursive;
  position: fixed;
  width: 100%;
  z-index: 1;
}
.headerImg {
  top: 0px;
  margin: auto;
  height: 50px;
  background-image: url("https://raw.githubusercontent.com/Tubidaor/portfolio-site/phase2/Herov3.jpg");
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 600%;
  background-position-y: 68%;
}
.navContainer {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  background-color: #232763;
  box-shadow: 0 0 5px 1px #232763;
  height: 34px;
}
.my-name {
  font-size: 1.5em;
  font-weight: bolder;
  color: #e94498;
  font-family: 'Ubuntu', sans-serif;
}
.menu-bg {
  z-index: -1;
  padding-top: 4px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -30px;
  background:#232763;
  border-radius: 15%;
  transition: 0.3s ease;
  display: none;
  width: 200px;
}
#hamburger {
  width: 35px;
  margin-top: 8px;
  margin-right: 10px;
  height: 30px;
  cursor: pointer;
}
.bar {
  height: 5px;
  width: 100%;
  background-color: #ea4498;
  display: block;
  border-radius: 5px;
  transition: 0.3s ease;
  margin-top: 4px;
}
#bar1 {
  transform: translateY(-2px);
  margin-top: -2px;
}
#bar3 {
  transform: translateY(2px);
  margin-top: 4px;
}
.menu {
  margin-left: 2px;
  margin-right: 2px;
}
.menu li a {
  color: #F9E100;
  text-decoration: none;
  font-weight: bold;
}
.menu li a:hover {
  color: #ea4498;
}
.menu li {
  text-align: left;
  list-style: none;
  margin-left: -20px;
}
.change-bg {
  display: block;
  position: absolute;
  right: 0px;
  top: 70px;
  z-index: 1;
  width: 110px;
}
.change .bar {
  background-color: #F9E100;
}
.change #bar1 {
  transform: translatey(14px) rotateZ(-45deg);
  margin-top: -7px;
}
.change #bar3 {
  transform: translateY(-4px) rotate(45deg);
  margin-top: 4px;
}
.change #bar2 {
  opacity: 0;
}
.menu.change {
  margin-right: 10px;
  margin-left: -4px;
  -webkit-padding-start: revert;
          padding-inline-start: revert;
}
.change {
  display: block;
}
.nav-bar {
  height: 34px;
}

@media screen and (min-width: 700px){
  .hamburger {
    display: none;
  }
  .menu {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: space-around;
    margin-top: 0px;
    margin-left: auto;
    margin-right: 0px;
    -webkit-margin-before: 0em;
            margin-block-start: 0em;
    -webkit-margin-after: 0em;
            margin-block-end: 0em;
    -webkit-padding-start: -1px !important;
            padding-inline-start: -1px !important;
  }
  .menu li::after{
    content: " |";
    color: #ea4498;
    font-weight: bold;
  }
  .menu li.item4::after{
    content: none;
  }
  .menu li {
    list-style: none;
    margin-left: 2px;
  }
  .menu-bg {
    margin-left: auto;
    margin-right: 10px;
    margin-top: -24px;
    background: none;
    border-radius: none;
    transition: none;
    display: block;
    width: 340px;
  }
  /* .change-bg {
    display: block;
    margin-top: -65px;
    width: 280px;
  } */
}
.mainPageHeader {
  display: none;
}
.containerMain {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: calc(100vh - 60px);
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  /* background-color: black;
  z-index: -3; */
}
.mainImg {
  position: relative;
  height: 400px;
  width: auto;
  top: 50px;
  left: -200px;
  right: auto;
  display: block;
}
.imgContainer {
  width: 44%;
  margin-left: auto;
  margin-right: auto;
}
.introDiv {
  width: 56%;
  height: 50vh;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 6px;
}

@media screen and (min-width: 700px) {
  .mainImg {
    left: 0px;
  }
  .introDiv {
    width: 50%;
  }
  .imgContainer {
    width: 50%;
  }
}

@media screen and (orientation: landscape) and (max-width: 736px) and (max-height: 414px) {
  .containerMain {
    margin-top: -140px;
    height: 160vh;
  }
  .imgContainer {
    margin-top: 60px;
  }
}
@media screen and (orientation: landscape) and (min-width: 812px) and (max-height: 375px) {
  .containerMain {
    margin-top: -140px;
    height: 160vh;
  }
  .introDiv {
    margin-top: 120px;
  }
  .imgContainer {
    margin-top: 60px;
  }
}


.intro {
  color: #232763;
  font-family: "Fascinate Inline";
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 0px 20px 20px rgba(225, 227, 234, 1);
  background-color: rgba(225, 227, 234, 1);
}
.h1Container {
  background:linear-gradient(rgba(255,255,255,.1), rgba(255,255,255,.8), rgba(255,255,255,.1));
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1.9rem;
}

/* @media screen and (min-width: 700px) {
  .intro {
    font-size: 2.5rem;
  }
} */

.bubbles {
  border-radius: 50%;
  z-index: -1;
}

.bubblesContainer {
  position: fixed;
  bottom: 0px;
  z-index: -1;
}
.resumePageContainer {
  margin-top: -26px;
}
.App {
  overflow: hidden;
}
main {
  margin-top: 60px;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}
.h1AboutMe {
  color: #232763;
  font-size: 2em;
  font-family: "Fascinate Inline";
  text-align: center;
}
.aboutMeContainer {
  width:90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}
.aboutMePContainer {
  background: #ffffff;
  border-radius: 10px;
  padding: 4px;
  font-size: 1em;
  border: 2px solid #232763;
  border-radius: 10px;
}
.aboutMeP {
  padding: 2px;
  font-family: monospace;
}
.projectsContainer {
  margin-bottom: 20px;
}
.project {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid #232763;
  border-radius: 10px;
  margin-top: 10px;
  background-color: rgba(225, 227, 234, 1);
  font-family: monospace;
}
.projectCon {
  margin-top: 25px;
}
.projectsH2Con {
  background-color: #232763;
  border-radius: 6px 6px 0px 0px;
  height: 50px;
  position: relative;
}
.projectsH2 {
  font-family: 'Righteous', cursive;
  -webkit-text-stroke: .1px #232763;
  font-size: 1.2em;
  color: #e94498;
  padding: 4px;
  position: absolute;
  margin: 0;              
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)

}
.projectP3 {
  font-size: 1em;
  font-family: monospace;
  margin-top: -10px;
  color: black;
  font-weight: 700;
}
.dateCreatedSpan {
  font-size: 1em;
  font-family: monospace;
  margin-top: -10px;
  color: black;
  font-weight: 700;
}
.project img {
  height: auto;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  border: 2px solid #232763;
  left: 0px;
  top: 0px;
}
.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2px;
  margin-bottom: 20px;
}
button {
  margin-top: 4px;
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  background-color: #232763;
  color: #e94498;
  font-size: .83em;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  padding-top: 2px;
}
.projectsP {
  font-size: 1em;
  font-family: monospace;
  margin-top: -10px;
  color: black;
  font-weight: 700;
}
.aboutMePContainer {
  background: rgba(225, 227, 234, 1);
  border-radius: 10px;
  padding: 4px;
  font-size: 1em;
  border: 2px solid #232763;
  border-radius: 10px;
}
img.techLogos {
  height: 40px;
  width: auto;
  margin: 1px;
}


@media screen and (min-width: 400px) {
  .project {
    width: 70%;
  }
}
@media screen and (min-width: 600px) {
  .project {
    width: 60%;
  }
}
@media screen and (min-width: 800px) {
  .project {
    width: 40%;
  }
}
.projectsH1 {
  color: #232763;
  font-size: 2em;
  font-family: "Fascinate Inline";
  text-align: center;
}
.projectsIntro {
  background: rgba(225, 227, 234, 1);
  border-radius: 10px;
  padding: 18px;
  font-size: 1.1em;
  border: 2px solid #232763;
  border-radius: 10px;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  text-align: left;
  font-family: monospace;
  font-weight: 700;
}
.resume {
  background-color: #d3cbb8;
}
.resumeMain {
  margin-top: 10px;
}
.resume .resumeHeader{
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
}
h2 {
  margin-bottom: 20px;
  color: black;
  font-size: 1.17rem;
}

.breakpoint {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  height: 4px;
  background-color: #facc6b;
  background-image: linear-gradient(315deg, #f5f2d0 0%, #facc6b 54%);
}
.breakpointAddLang1 {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  height: 4px;
  background-color: #facc6b;
  background-image: linear-gradient(315deg, #f5f2d0 0%, #facc6b 54%);
}
.breakpointHead {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  height: 4px;
  background-color: #facc6b;
  background-image: linear-gradient(315deg, #f5f2d0 0%, #facc6b 54%);
}
.introSection {
  font-size: .9em;
  display: block;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.skillsSection {
  display: block;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.profExSection {
  display: block;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.edSection {
  display: block;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.certMiscSection {
  display: block;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.footerResume {
  color: black;
  text-align: center;
  padding-bottom: 2px;
}
.footerResumeDiv {
  display: inline;
  background-color: #61dafb;
  border-radius: 4px;
}

@media screen and (min-width: 600px) {
  .resumeMain {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .leftHalf {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
  }
  .rightHalf {
    width: 25%;
    margin-left: auto;
    margin-right: auto;
  }
  .introSection {
    width: 100%;
  }
  .skillsSection {
    width: 100%;
  }
  .edSection {
    width: 100%;
  }
  .certMiscSection {
    width: 100%;
  }
  .breakpoint {
    width: 100%;
  }
  .breakpointAddLang1 {
    width: 100%;
  }
  
  .borderDiv div {
    width: 5px;
    height: 96%;
    margin-top: 20px;
    background-color: #facc6b;
    background-image: linear-gradient(315deg, #f5f2d0 0%, #facc6b 54%);
  }
  .profExSection {
    width: 100%;
  }
}

@media print {
  main {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .leftHalf {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
  }
  .rightHalf {
    width: 25%;
    margin-left: auto;
    margin-right: auto;
  }
  .introSection {
    width: 100%;
  }
  .skillsSection {
    width: 100%;
  }
  .edSection {
    width: 100%;
  }
  .certMiscSection {
    width: 100%;
  }
  .breakpoint {
    width: 100%;
  }
  .borderDiv div {
    width: 5px;
    height: 96%;
    margin-top: 20px;
    background-color: #facc6b;
    background-image: linear-gradient(315deg, #f5f2d0 0%, #facc6b 54%);
  }
  .profExSection {
    width: 100%;
  }
  .breakpointAddLang1 {
    width: 100%;
    margin-top:60px;
  }
  .jobsCont4 {
    padding-top: 25px;
  }
  footer {
    margin-top: 570px;
  }
}

.resumeHeader {
  height: 206px;
}
.resumeUl {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: disc;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.liHeader {
  list-style: none;
  margin-top: -24px;
  font-size: .85em;
}
.resumeH1 {
  margin-top: 0px;
  text-align: center;
  font-size: 1.5em;
}
.headerContainer {
  width: 260px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -16px;
}
.bubblesDiv {
  margin-left: -40px;
}
.resumeImg {
  height: 164px;
  width: auto;
  display: block;
  margin-left: -20px;
}
.imgDiv {
  overflow: hidden;
  border-radius: 50%;
  height: 115px;
  width: 115px;
  background-color: #facc6b;
  background-image: radial-gradient(circle at 100%, #facc6b 0%, #fabc3c 74%);
}
.firstBubble {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: black;
  position: absolute;
  margin-top: -96px;
  margin-left: 130px;
}
.secondBubble {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: black;
  position: absolute;
  margin-top: -110px;
  margin-left: 150px;
}
.thirdBubble {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: black;
  position: absolute;
  margin-top: -110px;
  margin-left: 174px;
}
.fourthBubble {
  height: 105px;
  width: 105px;
  border-radius: 50%;
  border: 2px solid black;
  position: absolute;
  margin-top: -110px;
  margin-left: 196px;
  text-align: center;
  background-color: #facc6b;
  background-image: radial-gradient(circle at 100%, #facc6b 0%, #fabc3c 74%);
}
.bubbleP {
  font-size: .825em;
  width: 80%;
  text-align: center;
  display: block;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 26px;
  margin-bottom: auto;
}
span {
  color: #4590e5;
  font-weight: bold;
}

@media screen and (min-width: 600px) {
  .liHeader {
    font-size: 1em;
  }
}

@media screen and (min-width: 800px) {
  .liHeader {
    font-size: 1em;
    padding-top: 14px
  }
}

@media print {
  .liHeader {
    font-size: 1em;
    padding-top: 14px
  }
}
.jobHeadingDiv1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: -20px;
}
.jobsCon h3 {
  font-size: 1rem;
  color: black;
}
.jobsCon h4 {
  font-size: .83rem;
  color: black;
}
.jobHeadingDiv2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -25px;
}
.dateDiv {
  display: flex;
  flex-direction: row;
  justify-content: right;
}
.jobTitle {
  margin: 0px;
}
.jobResponsibilities {
  font-size: .9em;
}
.ulJobs {
  display: flex;
  justify-content: left;
  flex-direction: column;
  flex-wrap: wrap;
  width: 90%;
  margin-top: 0px;
  list-style-type: none;
  -webkit-margin-before: 0em;
          margin-block-start: 0em;
  -webkit-margin-after: 0em;
          margin-block-end: 0em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
}
.liJobs {
  list-style: url('https://i.ibb.co/GJj3sDG/thumbsUp.png');
  margin-top: 2px;
  font-size: .9em;
  margin-left: 22px;
  display: list-item;
  text-align: none;
}
.jobsDiv {
  margin-top: 20px;
}
.labelsDiv {
  margin-top: -10px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}
.labelBubble1 {
  background-color: #4590e5;
  border: 1px solid black;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin-right: 4px;
  margin-top: auto;
  margin-bottom: auto;
}
.labelBubble2 {
  background-color: #facc6b;
  border: 1px solid black;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin-right: 4px;
  margin-top: auto;
  margin-bottom: auto;
}
.labelHolder {
  font-size: 1em;
  display: flex;
  justify-content: center;
  flex-direction: row;

}
.potential {
  height: 10px;
  background-color: #facc6b;
  background-image: linear-gradient(90deg, #4590e5 52%, #facc6b 72%);
  width: 30px;
  border: 1px solid black;
  border-radius: 2px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -12px;
}
.current {
  height: 100%;
  background-color: #4590e5;
  width: 40%;
  border-radius: 2px;
}
.ulSkills {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: auto;
  margin-left: auto;
  -webkit-margin-before: 0em;
          margin-block-start: 0em;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
  margin-block-start: 0em;
  -webkit-margin-after: 20px;
          margin-block-end: 20px;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
}
.liSkills {
  list-style: none;
  margin-top: -10px;
  font-size: .9em;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.liSkillContainer {
  width: 60px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.liSkillContainer {
  margin-left: auto;
  margin-right: auto;
}
.edContainer {
  margin-top: 20px;
  padding-bottom: 20px;
}
.schoolNameDiv {
  margin-top: 0px;
}
.school {
  margin-bottom: -20px;
}
.edDegreeYearDiv {
  display: flex;
  justify-content: space-between;
  margin-top: -34px;
}
.schoolNameDiv h5 {
  margin-top: -16px;
}
.school H3 {
  font-size: 1rem;
}
.school h4 {
  font-size: .83rem;
}
.schoolCity {
  margin-top: -10px;
  margin-bottom: 25px;
}
.liMisc {
  list-style: none;
  margin-top: -20px;
  display: list-item;
  text-align: none;
}
.ulMisc {
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: disc;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.miscP {
  display: inline-block;
  -webkit-margin-before: none;
          margin-block-start: none;
  -webkit-margin-after: none;
          margin-block-end: none;
  -webkit-margin-start: none;
          margin-inline-start: none;
  -webkit-margin-end: none;
          margin-inline-end: none;
}

