.projectsContainer {
  margin-bottom: 20px;
}
.project {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid #232763;
  border-radius: 10px;
  margin-top: 10px;
  background-color: rgba(225, 227, 234, 1);
  font-family: monospace;
}
.projectCon {
  margin-top: 25px;
}
.projectsH2Con {
  background-color: #232763;
  border-radius: 6px 6px 0px 0px;
  height: 50px;
  position: relative;
}
.projectsH2 {
  font-family: 'Righteous', cursive;
  -webkit-text-stroke: .1px #232763;
  font-size: 1.2em;
  color: #e94498;
  padding: 4px;
  position: absolute;
  margin: 0;              
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)

}
.projectP3 {
  font-size: 1em;
  font-family: monospace;
  margin-top: -10px;
  color: black;
  font-weight: 700;
}
.dateCreatedSpan {
  font-size: 1em;
  font-family: monospace;
  margin-top: -10px;
  color: black;
  font-weight: 700;
}
.project img {
  height: auto;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  border: 2px solid #232763;
  left: 0px;
  top: 0px;
}
.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2px;
  margin-bottom: 20px;
}
button {
  margin-top: 4px;
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  background-color: #232763;
  color: #e94498;
  font-size: .83em;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  padding-top: 2px;
}
.projectsP {
  font-size: 1em;
  font-family: monospace;
  margin-top: -10px;
  color: black;
  font-weight: 700;
}
.aboutMePContainer {
  background: rgba(225, 227, 234, 1);
  border-radius: 10px;
  padding: 4px;
  font-size: 1em;
  border: 2px solid #232763;
  border-radius: 10px;
}
img.techLogos {
  height: 40px;
  width: auto;
  margin: 1px;
}


@media screen and (min-width: 400px) {
  .project {
    width: 70%;
  }
}
@media screen and (min-width: 600px) {
  .project {
    width: 60%;
  }
}
@media screen and (min-width: 800px) {
  .project {
    width: 40%;
  }
}