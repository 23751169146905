.jobHeadingDiv1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: -20px;
}
.jobsCon h3 {
  font-size: 1rem;
  color: black;
}
.jobsCon h4 {
  font-size: .83rem;
  color: black;
}
.jobHeadingDiv2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -25px;
}
.dateDiv {
  display: flex;
  flex-direction: row;
  justify-content: right;
}
.jobTitle {
  margin: 0px;
}
.jobResponsibilities {
  font-size: .9em;
}
.ulJobs {
  display: flex;
  justify-content: left;
  flex-direction: column;
  flex-wrap: wrap;
  width: 90%;
  margin-top: 0px;
  list-style-type: none;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}
.liJobs {
  list-style: url('https://i.ibb.co/GJj3sDG/thumbsUp.png');
  margin-top: 2px;
  font-size: .9em;
  margin-left: 22px;
  display: list-item;
  text-align: none;
}
.jobsDiv {
  margin-top: 20px;
}